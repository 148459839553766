<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/reset-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                </div>
                <vs-input
                  type="email"
                  placeholder="E-mail Address"
                  v-model="payload.email"
                  class="w-full mb-2"
                />
                <span class="block text-sm mb-2 text-danger">{{ errors.first('email') }}</span>
                <vs-input
                  type="password"
                  placeholder="Password"
                  v-model="payload.password"
                  class="w-full mb-2"
                />
                <span class="block text-sm mb-2 text-danger">{{ errors.first('password') }}</span>
                <vs-input
                  type="password"
                  placeholder="Confirm Password"
                  v-model="payload.password_confirmation"
                  class="w-full mb-2"
                />
                <span class="block text-sm mb-2 text-danger">{{ errors.first('password_confirmation') }}</span>
                <div
                  class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8"
                >
                  <vs-button
                    type="border"
                    to="/pages/login"
                    class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >Go Back To Login</vs-button
                  >
                  <vs-button @click.prevent="storeData" class="w-full sm:w-auto"
                    >Reset</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      payload: {
        email: "",
        password: "",
        password_confirmation: ""
      }
    };
  },

  methods: {
    storeData() {
      const formData = new FormData();
      formData.set("email", this.payload.email);
      formData.set("password", this.payload.password);
      formData.set("password_confirmation", this.payload.password_confirmation);
      formData.set("token", this.$route.query._token);

      axios
        .post("auth/reset-password", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push({ name: "page-login" });
        })
        .catch(err => {
          this.$setErrorsFromResponse(err.data.errors);
          setTimeout(() => {
            this.$validator.errors.clear();
          }, 2000);
        });
    }
  }
};
</script>
